<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div class="site-wrapper overflow-hidden position-relative">
    <!--Site Header Area -->
    <header
      :class="{ scrolling: currentRouteName == 'privacy-policy', 'reveal-header': currentRouteName == 'privacy-policy' }"
      class="site-header site-header--menu-right landing-14-menu site-header--absolute site-header--sticky">
      <div class="container">
        <nav class="navbar site-navbar">
          <!-- Brand Logo-->
          <div class="brand-logo">
            <a href="/">
              <img src="@/assets/images/logo/logo-light.png" alt="" class="light-version-logo">
            </a>
          </div>
          <template v-if="currentRouteName == 'home'">
            <div class="menu-block-wrapper">
              <div class="menu-overlay"></div>
              <nav class="menu-block" id="append-menu-header">
                <div class="mobile-menu-head">
                  <div class="go-back">
                    <i class="fa fa-angle-left"></i>
                  </div>
                  <div class="current-menu-title"></div>
                  <div class="mobile-menu-close">&times;</div>
                </div>
                <ul class="site-menu-main">
                  <li class="nav-item nav-item-has-children">
                    <a href="#" class="nav-link-item drop-trigger">Hubungi Kami <i class="fas fa-angle-down"></i>
                    </a>
                    <ul class="sub-menu" id="submenu-9">
                      <li class="sub-menu--item">
                        <a target="whatsapp" style="display: block;" href="https://kirimwa.id/masukmasjid"><span
                            class="fab fa-whatsapp"></span> WhatsApp</a>
                      </li>
                      <li class="sub-menu--item">
                        <a target="instagram" style="display: block;" href="https://instagram.com/masukmasjid"><span
                            class="fab fa-instagram"></span> Instagram</a>
                      </li>
                      <li class="sub-menu--item">
                        <a target="facebook" style="display: block;"
                          href="https://www.facebook.com/MasukMasjidOfficial"><span class="fab fa-facebook"></span>
                          Facebook</a>
                      </li>
                      <li class="sub-menu--item">
                        <a target="twitter" style="display: block;" href="https://www.twitter.com/masukmasjid"><span
                            class="fab fa-twitter"></span> Twitter</a>
                      </li>
                      <li class="sub-menu--item">
                        <a target="email" style="display: block;" href="mailto://developer.masukmasjid@gmail.com"><span
                            class="fa fa-envelope"></span> Email</a>
                      </li>
                    </ul>
                  </li>
                  <!-- <li class="nav-item">
                  <a href="#" class="nav-link-item">Blog</a>
                </li>
                <li class="nav-item">
                  <a href="https://uxtheme.net/product-support/" class="nav-link-item">Support</a>
                </li> -->
                </ul>
              </nav>
            </div>
            <div class="header-btn header-btn-l-14 ms-auto d-none d-xs-inline-flex">
              <a target="_blank" class="btn btn trail-btn focus-reset" href="https://takmir.masukmasjid.com/register">
                Uji Coba Gratis
              </a>
            </div>
          </template>
          <template v-if="currentRouteName == 'privacy-policy'">
            <div class="menu-block-wrapper">
              <div class="menu-overlay"></div>
              <nav class="menu-block" id="append-menu-header">
                <div class="mobile-menu-head">
                  <div class="go-back">
                    <i class="fa fa-angle-left"></i>
                  </div>
                  <div class="current-menu-title"></div>
                  <div class="mobile-menu-close">×</div>
                </div>
                <ul class="site-menu-main">
                  <li class="nav-item">
                    <a href="#tv-header" class="nav-link-item">MasukMasjid TV</a>
                  </li>
                  <li class="nav-item">
                    <a href="#takmir-header" class="nav-link-item">Ruang Takmir</a>
                  </li>
                  <li class="nav-item">
                    <a href="#sahabat-header" class="nav-link-item">Sahabat Masjid</a>
                  </li>
                </ul>
              </nav>
            </div>
          </template>
          <!-- mobile menu trigger -->
          <div class="mobile-menu-trigger">
            <span></span>
          </div>
          <!--/.Mobile Menu Hamburger Ends-->
        </nav>
      </div>
    </header>
    <!-- navbar- -->
    <router-view />
  </div>
</template>

<style lang="css">
@import '@/assets/styles/bootstrap.css';
@import '@/assets/fonts/icon-font/css/style.css';
@import '@/assets/fonts/typography-font/typo.css';
@import '@/assets/fonts/fontawesome-5/css/all.css';
@import '@/assets/plugins/aos/aos.min.css';
@import '@/assets/styles/main.css';
</style>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'

const route = useRoute()
const currentRouteName = computed(() => {
  return route.name;
})
</script>