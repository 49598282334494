<template>
  <div class="home">
    <!-- Site Header -->
    <!-- Preloader -->
    <!-- <div id="loading">
    <div class="preloader">
     <img src="@/assets/images/preloader.gif" alt="preloader">
   </div>
   </div>    -->
    <!-- Hero Area -->
    <div class="hero-area-l-14 position-relative z-index-1 overflow-hidden">
      <div class="container">
        <div class="row position-relative justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 pr-0 " data-aos="fade-right" data-aos-duration="800"
            data-aos-once="true">
            <div class="content">
              <h1>Digitalisasi Kegiatan Masjid</h1>
              <p>Publikasi kegiatan masjid secara lebih luas kepada jamaah maupun seluruh ummat.</p>
              <a href="https://takmir.masukmasjid.com/register" class="btn focus-reset">Mulai uji coba gratis</a>
              <a target="google-play" href="https://play.google.com/store/apps/developer?id=MasukMasjid"
                class="btn focus-reset" style="background-color: transparent !important;"><img
                  src="@/assets/images/l4/google-play.svg" /></a>
            </div>
          </div>
          <div class="col-xl-7 col-lg-5 col-md-8 " data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
            <div class="banner-image-l-14">
              <img src="@/assets/images/l4/hero-img-new.png" alt="" class="w-100 mt-xl-n10">
            </div>
          </div>
        </div>
      </div>
      <div class="bg-shape-14"></div>
    </div>
    <!-- Brand-area -->
    <!-- <div class="brand-area-l-14">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h4>Masjid yang telah menggunakan layanan kami</h4>
          </div>
        </div>
        <div class="row img-grayscale">
          <div class="col-lg-12">
            <div
              class="brand-area-l-14-items d-flex justify-content-center justify-content-xl-between align-items-center flex-wrap ">
              <div class="single-brand " data-aos="fade-right" data-aos-duration="500" data-aos-once="true">
                <img src="images/l4/brand-logo.svg" alt="">
              </div>
              <div class="single-brand " data-aos="fade-right" data-aos-duration="700" data-aos-once="true">
                <img src="images/l4/brand-logo-1.svg" alt="">
              </div>
              <div class="single-brand " data-aos="fade-right" data-aos-duration="900" data-aos-once="true">
                <img src="images/l4/brand-logo-2.svg" alt="">
              </div>
              <div class="single-brand " data-aos="fade-right" data-aos-duration="1000" data-aos-once="true">
                <img src="images/l4/brand-logo-3.svg" alt="">
              </div>
              <div class="single-brand " data-aos="fade-right" data-aos-duration="1200" data-aos-once="true">
                <img src="images/l4/brand-logo-4.svg" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="brand-aarea-border-l14"></div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Content-Area-1 -->
    <div class="content-area-l-14-1 position-relative">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10 order-lg-1 order-1" data-aos="fade-right"
            data-aos-duration="1200" data-aos-once="true">
            <div class="h-100 section-heading-8 content text-lg-start text-center">
              <h2>Tampilan Informatif.</h2>
              <p>Seluruh informasi kegiatan masjid tertuang dalam satu tampilan yang menawan.</p>
            </div>
          </div>
          <div class="offset-xl-2 col-xl-6 col-lg-6 col-md-8 pl-xl-11 order-lg-1 order-0" data-aos="fade-left"
            data-aos-duration="1200" data-aos-once="true">
            <div class="content-img">
              <img src="@/assets/images/l4/content-img-informative.png" alt="" class="w-100 w-xl-auto">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content-Area-2 -->
    <div class="content-area-l-14-2">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6 col-md-8" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
            <div class="content-img pr-lg-13">
              <img src="@/assets/images/l4/content-img-clock.png" alt="" class="w-100">
            </div>
          </div>
          <div class="col-xl-4 offset-xl-2 col-lg-6 col-md-8 col-sm-10" data-aos="fade-left" data-aos-duration="800"
            data-aos-once="true">
            <div class="content section-heading-8 text-lg-start text-center">
              <h2>Tampilan Jam Digital.</h2>
              <p>Cocok digunakan pada sisi dalam masjid dengan lebih sedikit informasi sehingga tidak mengganggu ibadah.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Feature Area -->
    <div class="features-area-l-14">
      <div class="container bg-shape-img-2 position-relative">
        <div class="row features-area-l-14 justify-content-center mx-0">
          <div class="col-lg-6 col-md-8 col-sm-10 px-lg-6" data-aos="fade-right" data-aos-duration="800"
            data-aos-once="true">
            <div class="content-area  d-flex">
              <div class="circle-85 hover-icon">
                <img src="@/assets/images/l4/easy-icon.svg" alt="">
              </div>
              <div class="content-body pl-sm-14 pl-5">
                <h5>Kemudahan Akses</h5>
                <p class="mb-0">
                  Integrasi dengan Google SignOn menjadikan kemudahkan dalam akses tanpa harus menghafal banyak
                  password.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-8 col-sm-10" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
            <div class="content-area  d-flex ">
              <div class="circle-85 hover-icon">
                <img src="@/assets/images/l4/integration-icon.svg" alt="">
              </div>
              <div class="content-body">
                <h5>Data Terintegrasi</h5>
                <p class="mb-0">
                  Seluruh data pada MasukMasjid dapat saling terintegrasi antar layanan kami sehingga lebih
                  efisien dalam manajemen publikasi kegiatan masjid</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-8 col-sm-10" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
            <div class="content-area  d-flex ">
              <!-- circle-85 start -->
              <div class="circle-85 hover-icon">
                <img src="@/assets/images/l4/share-icon.svg" alt="">
              </div>
              <div class="content-body">
                <h5>Koneksi Antar Platform</h5>
                <p class="mb-0">
                  Integrasi dengan platform terkenal seperti Instagram, Facebook, Twitter, YouTube, dan
                  lainnya menjadikan kemudahan dalam publikasi kegiatan masjid secara lebih luas.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-8 col-sm-10" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
            <div class="content-area  d-flex ">
              <div class="circle-85  hover-icon">
                <img src="@/assets/images/l4/service-icon.svg" alt="" />
              </div>
              <div class="content-body">
                <h5>Berbasis Layanan</h5>
                <p class="mb-0">
                  Kami siap membantu jika ada kesulitan dalam penggunaan tanpa ada biaya tambahan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pricing-area section -->
    <div class="pricing-area-l14 position-relative overflow-hidden z-index-1">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-xl-6 col-lg-7 col-md-10 text-center" data-aos="fade-down" data-aos-duration="800"
            data-aos-once="true">
            <div class="section-heading-8">
              <h2>Harga Terjangkau</h2>
              <p>Publikasikan kegiatan masjid secara lebih luas dan terintegrasi kepada Jamaah anda dengan cara yang
                lebih mudah dari sebelumnya.</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center" id="table-price-value" data-pricing-dynamic data-value-active="monthly">
          <div class="col-md-12 text-center">
            <!-- toggle-btn start -->
            <div class="toggle-btn d-inline-block  justify-content-center">
              <a class="btn-toggle btn-toggle-2 active-white d-flex  price-deck-trigger" data-pricing-trigger
                data-target="#table-price-value" @click.prevent="switchPricingCycle">
                <span class="round"></span>
              </a>
            </div>
            <!-- toggle-btn end -->
          </div>
          <div class="col-md-12 col-sm-8 l-14-pricing-table row no-gutters">
            <div class="col-lg-3 col-md-6 border p-0">
              <!-- single-price start -->
              <div class="single-price bg-default text-center  pt-5 pb-10 mb-lg-0" data-aos="fade-right"
                data-aos-duration="800" data-aos-once="true">
                <!-- price-top start -->
                <div class="price-top justify-content-between mb-2">
                  <span>Bulanan 1</span>
                </div>
                <!-- price-top end -->
                <!-- main-price start -->
                <div class="main-price pb-8 border-bottom">
                  <div class="price mt-3 mb-3 position-relative">
                    <!-- <span class="d-inline-block mb-0 dynamic-value">IDR</span> -->
                    <h2 class="d-inline-block mb-0  dynamic-value" data-active="15" data-monthly="100rb"
                      data-yearly="1jt"></h2>
                  </div>
                  <span class=" dynamic-value" data-active="per bulan" data-monthly="Per bulan"
                    data-yearly="per tahun, hemat 200 ribu"></span>
                </div>
                <!-- main-price end -->
                <!-- price-body start -->
                <div class="price-body">
                  <ul class="pricing-list with-check-icon list-unstyled">
                    <li>1 Perangkat terhubung</li>
                    <li>10GB Penyimpanan Media</li>
                    <li>Jadwal Solat Otomatis</li>
                    <li>Dapat digunakan secara offline</li>
                  </ul>
                </div>
                <div class="price-btn price-btn-bro">
                  <a class="btn focus-reset" href="https://takmir.masukmasjid.com/register">Pasang Sekarang</a>
                </div>
                <!-- price-btn end -->
              </div>
              <!-- single-price end -->
            </div>
            <div class="col-lg-3 col-md-6 border p-0">
              <!-- single-price start -->
              <div class="single-price bg-default text-center position-relative  pt-5 pb-10 mb-lg-0" data-aos="fade-up"
                data-aos-duration="800" data-aos-once="true">
                <!-- price-top start -->
                <div class="price-top justify-content-between mb-2">
                  <span>Bulanan 3</span>
                </div>
                <!-- price-top end -->
                <!-- main-price start -->
                <div class="main-price pb-8 border-bottom">
                  <div class="price mt-3 mb-3 position-relative">
                    <h2 class="d-inline-block mb-0  dynamic-value" data-active="15" data-monthly="250rb"
                      data-yearly="2.5jt">
                    </h2>
                  </div>
                  <span class=" dynamic-value" data-active="per bulan" data-monthly="per bulan"
                    data-yearly="per tahun, hemat 500 ribu"></span>
                </div>
                <!-- main-price end -->
                <!-- price-body start -->
                <div class=" price-body">
                  <ul class="pricing-list with-check-icon list-unstyled">
                    <li>3 Perangkat terhubung</li>
                    <li>10GB Penyimpanan Media</li>
                    <li>Jadwal Solat Otomatis</li>
                    <li>Dapat digunakan secara offline</li>
                  </ul>
                </div>
                <div class="price-btn price-btn-bro">
                  <a class="btn focus-reset" href="https://takmir.masukmasjid.com/register">Pasang Sekarang</a>
                </div>
                <!-- price-btn end -->
              </div>
              <!-- single-price end -->
            </div>
            <div class="col-lg-3 col-md-6 border p-0">
              <!-- single-price start -->
              <div class="single-price bg-default text-center position-relative  pt-5 pb-10 mb-lg-0" data-aos="fade-up"
                data-aos-duration="800" data-aos-once="true">
                <!-- price-top start -->
                <div class="price-top justify-content-between mb-2">
                  <span>Bulanan 5</span>
                </div>
                <!-- price-top end -->
                <!-- main-price start -->
                <div class="main-price pb-8 border-bottom">
                  <div class="price mt-3 mb-3 position-relative">
                    <h2 class="d-inline-block mb-0  dynamic-value" data-active="15" data-monthly="400rb"
                      data-yearly="4jt">
                    </h2>
                  </div>
                  <span class=" dynamic-value" data-active="per bulan" data-monthly="per bulan"
                    data-yearly="per tahun, hemat 800 ribu"></span>
                </div>
                <!-- main-price end -->
                <!-- price-body start -->
                <div class=" price-body">
                  <ul class="pricing-list with-check-icon list-unstyled">
                    <li>5 Perangkat terhubung</li>
                    <li>10GB Penyimpanan Media</li>
                    <li>Jadwal Solat Otomatis</li>
                    <li>Dapat digunakan secara offline</li>
                  </ul>
                </div>
                <div class="price-btn price-btn-bro">
                  <a class="btn focus-reset" href="https://takmir.masukmasjid.com/register">Pasang Sekarang</a>
                </div>
                <!-- price-btn end -->
              </div>
              <!-- single-price end -->
            </div>
            <div class="col-lg-3 col-md-6 border p-0">
              <!-- single-price start -->
              <div class="single-price bg-default text-center position-relative  pt-5 pb-10 mb-lg-0" data-aos="fade-up"
                data-aos-duration="800" data-aos-once="true">
                <!-- price-top start -->
                <div class="price-top justify-content-between mb-2">
                  <span>Bulanan Unlimited</span>
                </div>
                <!-- price-top end -->
                <!-- main-price start -->
                <div class="main-price pb-8 border-bottom">
                  <div class="price mt-3 mb-3 position-relative">
                    <h2 class="d-inline-block mb-0  dynamic-value" data-active="15" data-monthly="500rb"
                      data-yearly="5jt">
                    </h2>
                  </div>
                  <span class=" dynamic-value" data-active="per bulan" data-monthly="per bulan"
                    data-yearly="per tahun, hemat 1 juta"></span>
                </div>
                <!-- main-price end -->
                <!-- price-body start -->
                <div class=" price-body">
                  <ul class="pricing-list with-check-icon list-unstyled">
                    <li>Perangkat terhubung tak terbatas</li>
                    <li>10GB Penyimpanan Media</li>
                    <li>Jadwal Solat Otomatis</li>
                    <li>Dapat digunakan secara offline</li>
                  </ul>
                </div>
                <div class="price-btn price-btn-bro">
                  <a class="btn focus-reset" href="https://takmir.masukmasjid.com/register">Pasang Sekarang</a>
                </div>
                <!-- price-btn end -->
              </div>
              <!-- single-price end -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CTA Area -->
    <div class="cta-area-l-14">
      <div class="container position-relative">
        <div class="bg-shape-img-3"></div>
        <div class="row cta-area-l-14-content justify-content-center text-lg-start text-center">
          <div class="col-lg-6">
            <div class="cta-content">
              <h2 class="">Tertarik dengan Layanan kami?</h2>
            </div>
          </div>
          <div class="col-lg-3 text-lg-end text-center">
            <a class="btn" href="https://takmir.masukmasjid.com/register">Uji Coba Gratis</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Area -->
    <footer class="footer-area-l-12 position-relative">
      <div class="container">
        <div class="row justify-content-center">
          <hr />
          <!-- MasukMasjid.com -->
          <div class="col-lg-12 col-md-11 pl-lg-12" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
            <div class="row">
              <div class="col-sm-2 col-xs-2 pl-lg-0">
                <div class="footer-widget widget4">
                  <p class="widget-title">Halaman Lain</p>
                  <ul class="widget-links pl-0 list-unstyled ">
                    <li><a href="https://sahabat.masukmasjid.com" target="_blank">Sahabat Masjid</a></li>
                    <li><a href="/privacy-policy">Kebijakan Privasi</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-2 col-xs-2 pl-lg-0">
                <div class="footer-widget widget4">
                  <p class="widget-title">Hubungi Kami</p>
                  <ul class="widget-links pl-0 list-unstyled ">
                    <li><a target="instagram" href="https://instagram.com/masukmasjid"><i class="fab fa-instagram"></i>
                        Instagram</a></li>
                    <li><a target="facebook" href="https://facebook.com/MasukMasjidOfficial"><i
                          class="fab fa-facebook"></i> Facebook</a></li>
                    <li><a target="youtube" href="https://youtube.com/channel/UCOaevtkaxPA1qIySmaX1vPw"><i
                          class="fab fa-youtube"></i> Youtube</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-3 col-xs-3">
                <div class="footer-widget widget2">
                  <p>&nbsp;</p>
                  <ul class="widget-links pl-0 list-unstyled">
                    <li><a target="whatsapp" href="https://kirimwa.id/masukmasjid"><i class="fab fa-whatsapp"></i>
                        WhatsApp</a></li>
                    <li><a target="twitter" href="https://twitter.com/masukmasjid"><i class="fab fa-twitter"></i>
                        Twitter</a></li>
                    <li><a target="email" href="mailto://developer.masukmasjid@gmail.com"><i class="fa fa-envelope"></i>
                        developer.masukmasjid@gmail.com</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-5 col-md-8 col-sm-11" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
            <div class="footer-subs-form-l-12">
              <p>Subscribe to our newsletter</p>
              <h6>Subscribe to get lastest offers, news and events announcements. No spam in your inbox.</h6>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Enter your email address" aria-label="">
                <div class="input-group-append">
                  <button class="btn border-0 focus-reset" type="button">
                    <i class="icon icon-tail-right text-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </footer>

  </div>
</template>

<script setup>
import { onMounted } from 'vue'

onMounted(() => {
  require('@/assets/js/vendor.min.js')
  require('@/assets/plugins/menu/menu.js')
  require('@/assets/js/custom')
})
</script>